import { Link } from "react-router-dom";
import "./styles/style.scss"

export function Footer() {
    return (
        <div className="footer">
            <div className='container'>
                <br /><br /><br /><br />
                <div className="footer-content">
                    <ul>
                        <li><Link to={'#'}>Qui sommes-nous?</Link></li>
                        <li><Link to={'#'}>Nous contacter</Link></li>
                        <li><Link to={'#'}>Politique de Confidentialité</Link></li>
                        <li><Link to={'#'}>Termes et Conditions</Link></li>
                        <li><Link to={'#'}>Termes et Conditions</Link></li>
                    </ul>
                </div>
                <br />
            </div>
        </div>
    );
}

export default Footer;
