export const product_last = [
    {
        "id": 1,
        "libelle": "Mouton",
        "slug": "Mouton-penelope",
        "content": "",
        "username": "corentinvt59",
        "price": "120",
        "etat": "",
        "brand": "",
        "localisation": "alsace",
        "distance": "1km",
        "image": "https://www.seneplus.com/sites/default/files/styles/section_hero/public/raw_photos/ladoum.jpg?itok=Cu-MLgdP",
    },
    {
        "id": 2,
        "libelle": "Mouton",
        "slug": "Mouton2",
        "content": "lorem ipsum dolor sit amet consectetur adipiscing elit",
        "resume": "",
        "username": "alaska125",
        "price": "45",
        "etat": "neuf",
        "brand": "Adidas",
        "localisation": "",
        "distance": "0.5km",
        "image": "https://sentinellebf.com/wp-content/uploads/2022/12/photostudio_1671032005814.jpg",
    },
    {
        "id": 3,
        "libelle": "Mouton",
        "slug": "Mouton3",
        "content": "lorem ipsum dolor sit amet consectetur adipiscing elit",
        "resume": "",
        "username": "clemence",
        "price": "40",
        "etat": "neuf",
        "brand": "Adidas",
        "localisation": "",
        "distance": "3km",
        "image": "https://lequotidien.sn/wp-content/uploads/2017/11/ladoum.jpg",
    },
    {
        "id": 4,
        "libelle": "Mouton",
        "slug": "Mouton4",
        "content": "lorem ipsum dolor sit amet consectetur adipiscing elit",
        "resume": "",
        "username": "kayliah",
        "price": "79",
        "etat": "comme neuf",
        "brand": "Alfa",
        "localisation": "",
        "distance": "2km",
        "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSY4fgVQXOTB2Uj_mf0Z8WDgetePpyup8zIdMIkeBmNw&s",
    },
    {
        "id": 5,
        "libelle": "Mouton",
        "slug": "Mouton5",
        "content": "lorem ipsum dolor sit amet consectetur adipiscing elit",
        "resume": "",
        "username": "hk26",
        "price": "8",
        "etat": "comme neuf",
        "brand": "Coltex",
        "localisation": "",
        "distance": "1km",
        "image": "https://sentinellebf.com/wp-content/uploads/2022/12/photostudio_1671032005814.jpg",
    },
    {
        "id": 6,
        "libelle": "Mouton",
        "slug": "Mouton6",
        "content": "lorem ipsum dolor sit amet consectetur adipiscing elit",
        "resume": "",
        "username": "",
        "price": "15",
        "etat": "tres bon etat",
        "brand": "Gopro",
        "localisation": "",
        "distance": "3km",
        "image": "https://www.seneplus.com/sites/default/files/styles/section_hero/public/raw_photos/ladoum.jpg?itok=Cu-MLgdP",
    }
]