import { configureStore } from '@reduxjs/toolkit';
import { homeApi } from "../features/home/service/home-api";
import { postApi } from '../features/posts/service/post-api';
import { authApi } from '../features/auth/services/auth-api';

export default configureStore({
    reducer: {
        [homeApi.reducerPath]: homeApi.reducer,
        [postApi.reducerPath]: postApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat
            (
                homeApi.middleware,
                postApi.middleware,
                authApi.middleware
            )
});