import React, { useState } from "react";
import { Button, Form, Input, Row, Col, Modal } from "antd";
import { useAuthRegisterMutation } from "./services/auth-api";
import { useNavigate, Link } from "react-router-dom";
import "./styles/style.scss";


export function RegisterModal({ handleCancel, status }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [authRegister] = useAuthRegisterMutation();


  const onFinish = (values) => {
    setIsLoading(true);
    authRegister(values)
      .unwrap()
      .then((res) => {
        navigate("/account/post-new");
      })
      .catch((error) => {
        console.log("error:====>", error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className="dialog"
      centered
      visible={status}
      footer={null}
      closable={false}
      onCancel={handleCancel}
    >
      <div className="auth-content">
        <Row>
          <Col lg={24} xs={24}>
            <div className="form-wrapper">
              <h3>Créer un compte</h3>
              <p>
                Pour acheter ou vendre en toute sécurité, créez un compte ou
                connnectez-vous.
              </p>
              <Form
                name="basic"
                initialValues={{ email: "", password: "" }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Nom"
                  name="nom"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Prénom"
                  name="prenom"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Téléphone"
                  name="number"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Creer un compte
                  </Button>
                </Form.Item>
              </Form>
              <p>
                Vous avez déjà un compte ?{" "}
                <strong onClick={() => handleCancel()}>Connexion</strong>
              </p>
              <p>
                En créant un compte et en vous connectant, vous acceptez les{" "}
                <Link to={"#"}> Conditions et Charte d'utilisation.</Link>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default RegisterModal;
