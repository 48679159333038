import { Col, Row, Avatar, Carousel } from "antd";
import { product_last } from "../../utils/product_last";
import { data } from "../../utils/data";
import { Link } from "react-router-dom";
import { HeartOutlined, UserOutlined } from "@ant-design/icons";
import "./styles/style.scss";

export function HomePage() {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <div className="home-page">
      <div className="container">
        <Carousel afterChange={onChange}>
          <div>
            <div
              className="banner"
              style={{ backgroundImage: `url('assets/img/slide-3.jpg'` }}
            ></div>
          </div>
          <div>
            <div
              className="banner"
              style={{ backgroundImage: `url('assets/img/slide-2.jpg'` }}
            ></div>
          </div>

          <div>
            <div
              className="banner"
              style={{ backgroundImage: `url('assets/img/slide-1.avif'` }}
            ></div>
          </div>

          <div>
            <div
              className="banner"
              style={{ backgroundImage: `url('assets/img/slide-1.jpg'` }}
            ></div>
          </div>
        </Carousel>
        <div className="cta-2">
          <h2>Nos valeurs</h2>
        </div>
        <div className="bottom-bar">
          <Row>
            <Col lg={6} xs={12}>
              <div className="blc-1">
                <div className="text">
                  <h2>Économie</h2>
                  <span>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="blc-2">
                <div className="text">
                  <h2>Solidarité</h2>
                  <span>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="blc-3">
                <div className="text">
                  <h2>Écologie</h2>
                  <span>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder
                  </span>
                </div>
              </div>
            </Col>

            <Col lg={6} xs={12}>
              <div className="blc-4">
                <div className="text">
                  <h2>Proximité</h2>
                  <span>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="section-main-page-heading">
          <h2>
            Le site officiel de petites annonces d’animaux de fermes <br />{" "}
          </h2>
        </div>
        <div className="section-title">
          <p>Autour de vous</p>
        </div>
        <Row gutter={20}>
          {product_last.map((item, index) => (
            <Col lg={6} xs={12} key={index}>
              <div className="product-item">
                <div className="user-info">
                  <div className="user-avatar">
                    <Avatar size="small" icon={<UserOutlined />} />
                  </div>
                  <div className="user-name">{item.username}</div>
                </div>
                <Link to={`/post-detail/${item.slug}`} state={item}>
                  <div
                    className="product-img"
                    style={{ backgroundImage: `url(${item.image})` }}
                  >
                    <div className="distance">{item.distance}</div>
                  </div>
                  <div className="product-title">{item.libelle}</div>
                  <div className="product-footer">
                    <div className="product-price">{item.price} €</div>
                    <div className="product-like">
                      <HeartOutlined />
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
        <div className="section-title">
          <p>Fil d'actualités</p>
        </div>

        <Row gutter={20}>
          {product_last.map((item, index) => (
            <Col lg={4} xs={12} key={index}>
              <div className="product-item">
                <div className="user-info">
                  <div className="user-avatar">
                    <Avatar size="small" icon={<UserOutlined />} />
                  </div>
                  <div className="user-name">{item.username}</div>
                </div>
                <Link to={`/post-detail/${item.slug}`} state={item}>
                  <div
                    className="product-img"
                    style={{ backgroundImage: `url(${item.image})` }}
                  >
                    <div className="distance">{item.distance}</div>
                  </div>
                  <div className="product-title">{item.libelle}</div>
                  <div className="product-footer">
                    <div className="product-price">{item.price} €</div>
                    <div className="product-like">
                      <HeartOutlined />
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>

        <div className="section-blog">
          <div className="section-title">
            <p>Nos derniers articles</p>
          </div>
          <div className="section-content">
            <Row gutter={24}>
              <Col lg={6} xs={12}>
                <div className="blc">
                  <div
                    className="blc-image"
                    style={{
                      backgroundImage: `url("https://static.nationalgeographic.fr/files/styles/image_3200/public/h_21.00319951.webp?w=1600&h=900&p=top")`,
                    }}
                  />
                  <div className="blc-content">
                    <div className="title">Coup de projecteur sur le mouton le plus cher du monde</div>
                    <div className="text">
                      Au Sénégal, le mouton ladoum peut coûter des dizaines de milliers d’euros. Mesurant jusqu’à 120 cm au garrot, il est utilisé comme animal de compagnie et confère un statut de haut rang à son propriétaire.
                    </div>
                    <div className="read-more">
                      <a href="@">Lire la suite</a>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6} xs={12}>
                <div className="blc">
                  <div
                    className="blc-image"
                    style={{
                      backgroundImage: `url("https://ichef.bbci.co.uk/ace/ws/800/cpsprodpb/d1e0/live/52100a10-be99-11ed-a9bc-7599d87091be.jpg")`,
                    }}
                  />
                  <div className="blc-content">
                    <div className="title">
                      Ladoum, ces moutons VIP qui n'ont rien à envier aux humains
                    </div>
                    <div className="text">
                      Au Sénégal, le mouton ladoum qui constitue la crème de l'élevage est traité avec beaucoup de soin et respect.
                    </div>
                    <div className="read-more">
                      <a href="@">Lire la suite</a>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6} xs={12}>
                <div className="blc">
                  <div
                    className="blc-image"
                    style={{
                      backgroundImage: `url("https://img.lemde.fr/2019/08/12/0/0/2835/1890/800/0/75/0/2395bd0_WqU-H4RepjWm-6u_ijqG7Kx3.jpg")`,
                    }}
                  />
                  <div className="blc-content">
                    <div className="title">
                      Le ladoum, mouton star de la Tabaski au Sénégal
                    </div>
                    <div className="text">
                      L’animal, célébré en raison de sa beauté et de ses dimensions exceptionnelles, est prisé des plus riches pour le sacrifice de la fête musulmane de l’Aïd-el-Kébir.
                    </div>
                    <div className="read-more">
                      <a href="@">Lire la suite</a>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6} xs={12}>
                <div className="blc">
                  <div
                    className="blc-image"
                    style={{
                      backgroundImage: `url("https://www.researchgate.net/profile/Abdou-Fall-4/publication/321429546/figure/fig2/AS:769156999806977@1560392727393/Troupeau-de-brebis-Ladoum-3-2-2-Raisons-delevage-habitats-et-equipements-Les-trois.jpg")`,
                    }}
                  />
                  <div className="blc-content">
                    <div className="title">
                      Troupeau de brebis Ladoum 3-2-2
                    </div>
                    <div className="text">
                      Troupeau de brebis Ladoum 3-2-2. Raisons d'élevage, habitats et équipements Les trois raisons déclarées qui guident les éleveurs à faire l'élevage de moutons Ladoum sont l'amélioration des revenus
                    </div>
                    <div className="read-more">
                      <a href="@">Lire la suite</a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
