import { Row, Col, Avatar } from "antd";
import { Link, useLocation } from "react-router-dom";
import { HeartOutlined, UserOutlined } from "@ant-design/icons";
import "./styles/style.scss";

export function SearchPage() {
  const location = useLocation();

  return (
    <div className="post-page">
      <div className="container">
        <div className="content-page">
          <div className="content-page-header">
            <span> Il y a {location.state.length} produit(s) </span>
          </div>

          <Row gutter={24}>
            <Col lg={24}>
              <div className="blc-right">
                <Row gutter={24}>
                  {location.state.map((item, index) => (
                    <Col lg={4} key={index}>
                      <div className="product-item">
                        <div className="user-info">
                          <div className="user-avatar">
                            <Avatar size="small" icon={<UserOutlined />} />
                          </div>
                          <div className="user-name">{item.username}</div>
                        </div>
                        <Link to={"/"}>
                          <div
                            className="product-img"
                            style={{ backgroundImage: `url(${item.image})` }}
                          />
                          <div className="product-title">{item.libelle}</div>
                          <div className="product-footer">
                            <div className="product-price">{item.price} €</div>
                            <div className="product-like">
                              <HeartOutlined />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
