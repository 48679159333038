import { Breadcrumb } from 'antd';

export function WishlistPage() {
    return (
        <div className='content shadow animate__animated animate__fadeIn'>
            <div className='breadcrumb'>
                <Breadcrumb>
                    <Breadcrumb.Item>Accueil</Breadcrumb.Item>
                    <Breadcrumb.Item>Compte</Breadcrumb.Item>
                    <Breadcrumb.Item>Mes favoris</Breadcrumb.Item>
                </Breadcrumb>
            </div>
        </div>
    );
}

export default WishlistPage;
