import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";

export const homeApi = createApi({
    reducerPath: "homeApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
    tagTypes: ["Home"],
    endpoints: (builder) => ({
        homeFetchOne: builder.query({
            query: () => `/posts?per_page=1`,
            providesTags: ["Home"]
        }),

        homeFetchCarriere: builder.query({
            query: () => `/posts/category/7/3/`,
            providesTags: ["Home"]
        }),

        homeFetchArgent: builder.query({
            query: () => `/posts/category/8/3/`,
            providesTags: ["Home"]
        }),

        homeFetchMaison: builder.query({
            query: () => `/posts/category/24/3/`,
            providesTags: ["Home"]
        }),
    }),
});

export const {
    useHomeFetchOneQuery,
    useHomeFetchCarriereQuery,
    useHomeFetchArgentQuery,
    useHomeFetchMaisonQuery
} = homeApi;
