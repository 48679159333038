export const data = [
  {
    id: 1,
    libelle: "Mouton Ladoume",
    slug: "equitation",
    resume:
      "Videz votre sellerie en toute sécurité. Retrouvez tous les articles neufs et d'occasion d'Mouton. Achetez et vendez votre matériel d'Mouton.",
    image: "",
    products: [
      {
        id: 1,
        libelle: "bavette pénélope",
        slug: "bavette-penelope",
        content: "",
        username: "corentinvt59",
        price: "120",
        etat: "neuf",
        brand: "Adidas",
        localisation: "alsace",
        distance: "1km",
        image:
          "https://images1.vinted.net/t/02_010d5_YmERMPPMNhMMzvS7E77fuAbW/f800/1657709496.jpeg?s=23cef37216d61c4340893f8f5dcc428a9ae4663d",
      },
      {
        id: 2,
        libelle: "Gilet de Protection EFT Wanabee",
        slug: "gilet-de-protection-EFT-Wanabee",
        content:
          "Gilet de Protection Mouton Mousse articulées pour un meilleur confort du cavalier neuf",
        resume: "",
        username: "alaska125",
        price: "45",
        etat: "neuf",
        brand: "Adidas",
        localisation: "",
        distance: "3km",
        image:
          "https://images1.vinted.net/t/02_021c9_Zu67YpuL1AzhJMJq568ou2Qf/f800/1657816402.jpeg?s=942a58a98d4f946cb51123e9f095b7c7030e1fc2",
      },
      {
        id: 3,
        libelle: "Bottes et bombe d’Mouton",
        slug: "bottes-et-bombe-equitation",
        content: "",
        resume: "",
        username: "clemence",
        price: "40",
        etat: "neuf",
        brand: "Adidas",
        localisation: "",
        distance: "1km",
        image:
          "https://images1.vinted.net/t/03_013e0_fSwtsPu1bd1V2Ry7aUNxtZ3b/f800/1657829784.jpeg?s=50aa4d6ec0dc48175f8cd0a30565b38643d01e4b",
      },
      {
        id: 4,
        libelle: "Veste de concours Mouton",
        slug: "veste-de-concours-equitation",
        content: "Porté en concours prix discutable si départ rapide",
        resume: "",
        username: "kayliah",
        price: "79",
        etat: "comme neuf",
        brand: "Alfa",
        localisation: "",
        distance: "2km",
        image:
          "https://images1.vinted.net/t/02_022b1_daVXn2GfY6TMQZPipJkaJ5FD/f800/1657112318.jpeg?s=7e13504be98d541709be848424aa575bd146e0b1",
      },
      {
        id: 5,
        libelle: "Legging d’Mouton ultra strech",
        slug: "legging-equitation-ultra-strech",
        content:
          "Ultra strech et léger comme un legging avec poche pour le téléphone.",
        resume: "",
        username: "hk26",
        price: "8",
        etat: "comme neuf",
        brand: "Coltex",
        localisation: "",
        distance: "0.3km",
        image:
          "https://images1.vinted.net/t/02_018e3_jTWsCGgfD624BsU7zoZ3WFzt/f800/1658078264.jpeg?s=743b1d868612e70b42c6f78f8c8aaf1922aadb81",
      },
      {
        id: 6,
        libelle: "Gilet protection Mouton 8 10 ans",
        slug: "gilet-protection-equitation",
        content:
          "Gilet de protection Mouton Fouganza 8 / 10 ans. Porté peu de fois.",
        resume: "",
        username: "",
        price: "15",
        etat: "tres bon etat",
        brand: "Gopro",
        localisation: "",
        distance: "2km",
        image:
          "https://images1.vinted.net/t/01_00444_tJMugJZZGT2g1Tdhu51sj8KU/f800/1657901975.jpeg?s=86b360a17c7b23939299f42a367daeafaac46a75",
      },
      {
        id: 7,
        libelle: "Casque Mouton kb",
        slug: "casque-equitation-kb",
        content:
          "Casque Mouton noir Knightsbridge (kb) Taille M 54-56 Leger et confortable, j’ai une autre mousse au cas où ( photo 12) & j’ai encore la boîte( photo 2) Achète neuf : chez kramer Mouton il y a 7 mois à 75€ Quelques légères rayures du aux branches , et le haut de la lettre « b » est légèrement abîmé( photo 7)sinon super état Je le vend car j’ai acheté un antares / photo portée( photo 13)  —->>> j’aimerai le vendre rapidement donc faites des offres",
        resume: "",
        username: "thais",
        price: "50",
        etat: "bon etat",
        brand: "Gopro",
        localisation: "",
        distance: "0.3km",
        image:
          "https://images1.vinted.net/t/02_004fb_AKYePfBL27RbukpQoe7WJiJe/f800/1657056588.jpeg?s=7a278dafc6a36738cc7408d48f98708b296e3cbe",
      },
      {
        id: 8,
        libelle: "Pantalon Mouton neuf avec étiquette Pfiff 40",
        slug: "pantalon-equitation-neuf-avec-etiquette",
        content:
          "neuf avec étiquette taille 40. ( taille un petit peu petit ) bleu et noir",
        resume: "",
        username: "delphine",
        price: "28",
        brand: "Highlander",
        etat: "etat correct",
        localisation: "",
        distance: "2km",
        image:
          "https://images1.vinted.net/t/03_026a6_D31e5TGiJuxYGNYrxn44avdo/f800/1638285437.jpeg?s=002a42bb400e513d21ff31c20d278e87ae6e330d",
      },
    ],
  },
  {
    id: 2,
    libelle: "Ski",
    slug: "ski",
    resume:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    image: "",
    products: [
      {
        id: 1,
        libelle: "Casquette sport",
        content:
          "Conçues pour protéger les pieds des nageurs dans leurs déplacements en bord de bassin. Adhérence et pieds au sec.",
        resume: "Conçues pour protéger les pieds des nageurs.",
        username: "corentinvt59",
        price: "28",
        distance: "1km",
        image:
          "https://images1.vinted.net/t/02_008ae_m8wyWqTKgCfBewgf1e23BbWZ/310x430/1657688061.jpeg?s=1ea69b1a4c2bf9027fc0e5d6e9efdb1766440bb2",
      },
    ],
  },
  {
    id: 3,
    libelle: "Golf",
    slug: "golf",
    resume:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    image: "",
    products: [],
  },
  {
    id: 4,
    libelle: "Fitness",
    slug: "fitness",
    resume:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    image: "",
    products: [],
  },
  {
    id: 5,
    libelle: "Cyclisme",
    slug: "cyclisme",
    resume:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    image: "",
    products: [],
  },
  {
    id: 6,
    libelle: "Nautique",
    slug: "nautique",
    resume:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    image: "",
    products: [],
  },
  {
    id: 7,
    libelle: "Handisport",
    slug: "handisport",
    resume:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    image: "",
    products: [],
  },
];
