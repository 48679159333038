import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Form, Input, Button, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { data } from "../../utils/data";
import LoginModal from "../../features/auth/login-modal";
import WelcomeModal from "../../features/auth/welcome-modal";
import "./styles/style.scss";

export function Header() {
  const [isLogin, setIsLogin] = useState(false);
  const [isWelcome, setIsWelcome] = useState(true);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     console.log("Latitude is :", position.coords.latitude);
  //     console.log("Longitude is :", position.coords.longitude);
  //   });
  // }, []);

  const sub_menu_one = (
    <div className="sub-menu">
      {data.map((item, index) => (
        <NavLink key={index} to={`/${item.slug}`} state={item}>
          {item.libelle}
        </NavLink>
      ))}
    </div>
  );

  return (
    <div className="header">
      <LoginModal status={isLogin} handleCancel={() => setIsLogin(false)} />
      {/* <WelcomeModal
        status={isWelcome}
        handleCancel={() => setIsWelcome(false)}
      /> */}
      <div className="top-bar">
        <div className="container">
          <center>
            Nos équipes sont à votre écoute du lundi au vendredi 09H-12h30 et
            13h30-17H au 09 32 44 25 46
          </center>
        </div>
      </div>
      <div className="content-bar content-bar-desktop">
        <div className="container">
          <Row>
            <Col lg={4} xs={4}>
              <div className="logo">
                <h1>
                  <NavLink to={"/"}>
                    <span>Univers des </span>
                    LANDOUMS
                  </NavLink>
                </h1>
              </div>
            </Col>
            <Col
              lg={12}
              xs={12}
              style={{ margin: "auto" }}
              className="search-bloc"
            >
              <div className="search-form">
                <Form autoComplete="off">
                  <div style={{ display: "flex" }}>
                    <Input
                      className="form-control"
                      placeholder="Rechercher..."
                    />
                    <Button
                      type=""
                      htmlType="submit"
                      style={{ background: "#eeeeee" }}
                    >
                      <NavLink to={`/search`} state={data[0]["products"]}>
                        <SearchOutlined />
                      </NavLink>
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col style={{ margin: "auto 0px" }}>
              <div className="blc-right">
                <ul>
                  <li
                    className="text-connexion"
                    onClick={() => setIsLogin(true)}
                  >
                    <p>MON COMPTE </p>
                    <span> Se connecter </span>{" "}
                  </li>
                  <li>
                    <span className="post-publish">
                      <NavLink to={"/account/post-new"}>
                        Publier une annonce
                      </NavLink>
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="content-bar content-bar-mobile">
        <div className="container">
          <div className="cta">
            <h2>Vendez, échangez, donnez!</h2>
          </div>

          <div className="top-content">
            <div className="logo">
              <h1>
                <NavLink to={"/"}>
                  <img src="assets/img/logo.jpg" alt="" />{" "}
                </NavLink>
              </h1>
            </div>
            <ul>
              <li className="text-connexion" onClick={() => setIsLogin(true)}>
                <p>MON COMPTE </p>
              </li>
              <li>
                <span className="post-publish">
                  <NavLink to={"/account/post-new"}>
                    Publier une annonce
                  </NavLink>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="navbar">
        <div className="container">
          <div className="menu">
            <ul>
              <li>
                <NavLink to={"/"}>Accueil</NavLink>
              </li>
              <li>
                <NavLink to={"/concept"}>Bergeries</NavLink>
              </li>
              {/* <li>
                <Popover content={sub_menu_one}>
                  <a href>Votre sport</a>
                </Popover>
              </li> */}
              <li>
                <NavLink to={"/blog"}>Actualités</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
