import { NavLink } from 'react-router-dom';


export function Sidebar() {

    return (
        <div className='sidebar shadow'>
            <h3>MON COMPTE</h3>
            <div className='menu'>
                <ul>
                    <li><NavLink to={'/account/profile'}>Profil</NavLink></li>
                    <li><NavLink to={'/account/posts'}>Mes annonces</NavLink></li>
                    <li><NavLink to={'/account/post-new'}>Publier une annonce</NavLink></li>
                    <li><NavLink to={'/account/inbox'}>Boite de réception</NavLink></li>
                    <li><NavLink to={'/account/wishlist'}>Mes favoris</NavLink></li>
                    <li><NavLink to={'/account/password-change'}>Modifier le mot de passe</NavLink></li>
                    <li><a href>Deconnexion</a></li>
                </ul>
            </div>
        </div>
    )
}
export default Sidebar;