import React, { useState } from "react";
import { Breadcrumb, Row, Col, Button, Select } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./styles/style.scss";

export function PostPage() {
  // const { TextArea } = Input;
  const location = useLocation();
  // const [isShow, setIsShow] = useState(false);
  const [isSignaler, setIsSignaler] = useState(false);

  const { Option } = Select;

  const handleChange = () => {
    setIsSignaler(false);
  };

  return (
    <div className="post-page post-detail">
      <div className="container">
        <div className="banner">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={"/"}>Accueil</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={"/"}>Mouton</NavLink>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={"/"}>Mouton</NavLink>{" "}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="banner-title">
            <h2>MOUTON</h2>
            <span>
              Videz votre sellerie en toute sécurité. Retrouvez tous les
              articles neufs et d'occasion d'Mouton. Achetez et vendez votre
              matériel d'Mouton.
            </span>
          </div>
        </div>

        <div className="content">
          <Row>
            <Col lg={16} xs={24}>
              <div className="aside-left">
                <div>
                  <img
                    src={location.state.image}
                    alt="img"
                    className="image-viewer"
                  />
                  {/* <div className="favoris">
                    <HeartOutlined />
                  </div> */}
                </div>
              </div>
            </Col>

            <Col lg={8}>
              <div className="aside-right">
                <div className="product">
                  <h2>{location.state.libelle}</h2>
                  <div className="price">{location.state.price}€</div>
                  <div className="distance">
                    Distance: <span>{location.state.distance}</span>
                  </div>
                  <div className="transaction">
                    Transaction disponible:<span> Échange</span>
                  </div>

                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip
                  </div>
                  <br />
                  <Button
                    type="primary"
                    className="add-to-cart"
                    // onClick={() => setIsShow(true)}
                  >
                    Contacter le propriétaire{" "}
                  </Button>
                  <br />

                  {isSignaler ? (
                    <div
                      className="signaler"
                      onClick={() => setIsSignaler(false)}
                    >
                      <ExclamationCircleOutlined /> Annuler
                    </div>
                  ) : (
                    <div
                      className="signaler"
                      onClick={() => setIsSignaler(true)}
                    >
                      <ExclamationCircleOutlined /> Signaler cette annonce
                    </div>
                  )}
                  {isSignaler && (
                    <div style={{ paddingRight: "" }}>
                      <Select
                        onChange={handleChange}
                        placeholder="Raison"
                        style={{
                          width: "200px",
                        }}
                      >
                        <Option value="1">Business is closed</Option>
                        <Option value="2">Fraud</Option>
                        <Option value="3">Incorrect Contact Information</Option>
                      </Select>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <Modal
        title="CONTACTER LE PROPRIÉTAIRE"
        className="auth"
        centered
        visible={isShow}
        footer={null}
        closable={false}
      >
        <div className="modal-body">
          <Form name="basic" layout="vertical" autoComplete="off">
            <Form.Item label="Téléphone" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Message" name="message">
              <TextArea rows={4} />
            </Form.Item>
          </Form>
          <div>
            <Button
              type="primary"
              className="add-to-cart"
              onClick={() => setIsShow(false)}
              block
            >
              Envoyer
            </Button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default PostPage;
