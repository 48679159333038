import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    // prepareHeaders: (headers, { getState }) => {
    //   const token = getState().auth.token;
    //   headers.set("Authorization", `Bearer ${token}`);
    //   return headers;
    // },
  }),
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    authlogin: builder.mutation({
      query: (payload) => ({
        url: "/api/login",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    // authCheckConfirmCode: builder.mutation({
    //   query: (payload) => ({
    //     url: "/api/v1/auth/check-confirm-code",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    authRegister: builder.mutation({
      query: (payload) => ({
        url: "/api/register",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    // authInitiateVerification: builder.mutation({
    //   query: (payload) => ({
    //     url: "/api/v1/sms/initiate-verification",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    // authCheckVerificationCode: builder.mutation({
    //   query: (payload) => ({
    //     url: "/api/v1/sms/check-verification-code",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    // authFetchToken: builder.mutation({
    //   query: (payload) => ({
    //     url: `/api/v1/auth`,
    //     method: "GET",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    // authCheckUser: builder.mutation({
    //   query: (payload) => ({
    //     url: "/api/v1/auth/check-user",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    // authRefreshToken: builder.mutation({
    //   query: (payload) => ({
    //     url: "/api/v1/auth/refresh-token",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    // authChangePassword: builder.mutation({
    //   query: (payload) => ({
    //     url: `/api/v1/user/${payload.id}/change-password`,
    //     method: "PUT",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),

    // authResetPassword: builder.mutation({
    //   query: (payload) => ({
    //     url: `/api/v1/user/reset-password`,
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Auth"],
    // }),
  }),
});

export const {
  useAuthloginMutation,
  useAuthRegisterMutation
} = authApi;
