import React, { useState, useEffect, useCallback } from "react";
import { Breadcrumb, Row, Col, Select, Radio, Space, Avatar } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HeartOutlined, UserOutlined } from "@ant-design/icons";
import "./styles/style.scss";
const { Option } = Select;

export function PostsPage() {
  const location = useLocation();
  const [filteredList, setFilteredList] = useState(location.state.products);
  const [selectedState, setSelectedState] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");

  const filterByState = useCallback(
    (filteredData) => {
      if (!selectedState) {
        return filteredData;
      }
      const data = filteredData.filter((item) => item.etat === selectedState);
      return data;
    },
    [selectedState]
  );

  const handleChangeState = (e) => setSelectedState(e.target.value);

  const filterByBrand = useCallback(
    (filteredData) => {
      if (!selectedBrand) {
        return filteredData;
      }
      const data = filteredData.filter((item) => item.brand === selectedBrand);
      return data;
    },
    [selectedBrand]
  );
  const handleChangeBrand = (e) => setSelectedBrand(e.target.value);

  useEffect(() => {
    var filteredData = filterByState(location.state.products);
    filteredData = filterByBrand(filteredData);
    setFilteredList(filteredData);
  }, [location, filterByState, filterByBrand]);

  const handleChange1 = (value) => {
    console.log(`selected ${value}`);
  };

  const handleChange2 = (value) => {
    console.log(`selected ${value}`);
  };

  const handleChange3 = (value) => {
    console.log(`selected ${value}`);
  };

  const handleReset = () => {
    setFilteredList(location.state.products);
    setSelectedState("");
    setSelectedBrand("");
  };

  return (
    <div className="post-page">
      <div className="container">
        <div className="banner">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={"/"}>Accueil</NavLink>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={"/"}>Produits</NavLink>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{location.state.libelle}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="banner-title">
            <h2>{location.state.libelle}</h2>
            <span>{location.state.resume}</span>
          </div>
        </div>
        <div className="content-page">
          <div className="content-page-header">
            <span> Il y a {filteredList.length} produit(s) </span>
            <Select
              placeholder="Choisissez une valeur"
              defaultValue={"1"}
              style={{ width: "150px" }}
              onChange={handleChange3}
            >
              <Option value="1">Pertinence</Option>
              <Option value="2">Prix croissant</Option>
              <Option value="3">Prix décroissant</Option>
            </Select>
          </div>

          <Row gutter={24}>
            <Col lg={5} xs={24}>
              <div className="blc-left">
                <div className="blc-title">Filtrer</div>
                <div className="section">
                  <h3>État</h3>
                  <Radio.Group
                    onChange={handleChangeState}
                    value={selectedState}
                  >
                    <Space direction="vertical">
                      <Radio value="neuf">Neuf</Radio>
                      <Radio value="comme neuf">Comme neuf</Radio>
                      <Radio value="tres bon etat">Très bon état</Radio>
                      <Radio value="bon etat">Bon état</Radio>
                      <Radio value="etat correct">État correct</Radio>
                    </Space>
                  </Radio.Group>
                </div>

                <div className="section">
                  <h3>Marque</h3>
                  <Radio.Group
                    onChange={handleChangeBrand}
                    value={selectedBrand}
                  >
                    <Space direction="vertical">
                      <Radio value="Adidas">Adidas</Radio>
                      <Radio value="Alfa">Alfa</Radio>
                      <Radio value="Atlas">Atlas</Radio>
                      <Radio value="Coltex">Coltex</Radio>
                      <Radio value="Gopro">Gopro</Radio>
                      <Radio value="Highlander">Highlander</Radio>
                    </Space>
                  </Radio.Group>
                </div>

                <div className="section">
                  <h3>Prix</h3>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Choisissez une valeur"
                    onChange={handleChange1}
                  >
                    <Option value="1">0€ - 9,99€</Option>
                    <Option value="2">10€ - 19,99€</Option>
                  </Select>
                </div>

                <div className="section">
                  <h3>TRANSACTION</h3>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Choisissez une valeur"
                    onChange={handleChange1}
                  >
                    <Option value="Echange">Echange</Option>
                    <Option value="Vente">Vente</Option>
                    <Option value="Don">Don</Option>
                  </Select>
                </div>

                <div className="section">
                  <h3>Localisation</h3>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Choisissez une valeur"
                    onChange={handleChange2}
                  >
                    <Option value="1">0-5km</Option>
                    <Option value="2">5-10km</Option>
                    <Option value="3">10-20km</Option>
                    <Option value="4">20km+</Option>
                  </Select>
                </div>
              </div>
              <h4 className="reset-filter" onClick={() => handleReset()}>
                Annuler le filtre
              </h4>
            </Col>

            <Col lg={19}>
              <div className="blc-right">
                <Row gutter={20}>
                  {filteredList &&
                    filteredList.map((item, index) => (
                      <Col lg={6} xs={12} key={index}>
                        <div className="product-item">
                          <div className="user-info">
                            <div className="user-avatar">
                              <Avatar size="small" icon={<UserOutlined />} />
                            </div>
                            <div className="user-name">{item.username}</div>
                          </div>
                          <Link to={"/"}>
                            <div
                              className="product-img"
                              style={{ backgroundImage: `url(${item.image})` }}
                            >
                              <div className="distance">{item.distance}</div>
                            </div>
                            <div className="product-title">{item.libelle}</div>
                            <div className="product-footer">
                              <div className="product-price">
                                {item.price} €
                              </div>
                              <div className="product-like">
                                <HeartOutlined />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default PostsPage;
