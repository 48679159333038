import "./styles/style.scss"

export function BlogPage() {
    return (
        <div className="blog-page">
            <div className='container'>
            </div>
        </div>
    );
}

export default BlogPage;
