import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Layout, Row, Col } from "antd";
import HomePage from "./features/home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PostsPage from "./features/posts/posts-page";
import BlogPage from "./features/blog";
import Sidebar from "./features/account/components/Sidebar";
import AccountPage from "./features/account";
import ProfilePage from "./features/account/profile";
import PostListPage from "./features/account/posts/post-list-page";
import PostNewPage from "./features/account/posts/post-new-page";
import WishlistPage from "./features/account/wishlist";
import InboxPage from "./features/account/inbox";
import PasswordChangePage from "./features/account/passwordChange";
import PostPage from "./features/posts/post-page";
import SearchPage from "./features/search/search-page";
import ConceptPage from "./features/concept";

const { Content } = Layout;

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:category" element={<PostsPage />} />
        <Route path="/post-detail/:slug" element={<PostPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/concept" element={<ConceptPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route element={<AccountWrapper />}>
          <Route path="/account" element={<AccountPage />} />
          <Route path="/account/profile" element={<ProfilePage />} />
          <Route path="/account/posts" element={<PostListPage />} />
          <Route path="/account/post-new" element={<PostNewPage />} />
          <Route path="/account/inbox" element={<InboxPage />} />
          <Route path="/account/wishlist" element={<WishlistPage />} />
          <Route
            path="/account/password-change"
            element={<PasswordChangePage />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

const AccountWrapper = () => {
  return (
    <Layout>
      <Content>
        <div className="account-page">
          <div className="container">
            <Row gutter={24}>
              <Col lg={6} className="animate__animated animate__fadeIn">
                <Sidebar />
              </Col>
              <Col lg={18}>
                <Outlet />
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
export default App;
